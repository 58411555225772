




































































































































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
import { Dictionary } from 'vue-router/types/router'
// import CompanyDetails from '../../Customers/СompanyDetails/СompanyDetails.vue';
import AddressSelect from '@/components/Controls/AddressSelect.vue'

@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form', 'formDirty',
    ]),
  },
  methods: {
    getFieldError,
  },
  validations() {
    const rules: any = {
      category_id: { required },
      name: { required },
      brand_owner: {},
      inn: {},
      kpp: {},
      owner_type: {},
      has_patent: {},
      reg_info: {},
      reg_date: {},
      reg_date_to: {},
      website: {},
      phone_hotline: {},
      show_on_mainpage: {},
      owner_id: {},
      icon_upload_id: {},
      legal_address: {},
    }

    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
    AddressSelect,
  },
})
export default class CreateContractWithCompany extends Vue {

  public FILES_HOST = process.env.VUE_APP_UBIC_SERVER_ADDRESS

  public dropFiles = []

  get isNew() {
    return !Number.isInteger(Number(this.$route.params.id))
  }

  get titleStack() {
    return [
      'Бренды', (this.isNew ? 'Новый бренд' : 'Редактирование бренда'),
    ]
  }

  public organisation_types: any = {
    ip: { name: 'ИП' },
    ooo: { name: 'ООО' },
  }

  get organisationTypesList() {
    return Object.keys(this.organisation_types).map((k) => {
      const t: any = this.organisation_types[k]
      return {
        name: t.name,
        value: k,
      }
    })
  }

  public changeValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field, newValue: value,
    })

    // @ts-ignore
    this.$v.form[field].$touch()
  }

  public submitObject() {
    this.$v.form.$touch()

    if (!this.$v.form.$error) {
      this.$store.dispatch(`${STORE_KEY}/saveObject`, this.$route.params.id)
        .then((result) => {
          this.$buefy.snackbar.open(`Данные успешно сохранены`)


          if ('name' in this.$route.params) {
            if ('id' in this.$route.params) {
              this.$router.push({ name: 'merchant-edit', params: this.$route.params })
            }
            else {
              this.$router.push({ name: 'merchant-new', params: this.$route.params })
            }
          }
          else {
            this.$router.push({ name: 'brand-list' })
          }
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            type: 'is-warning',
            message: `При сохраненит возникли проблемы. Попробуйте позже.`,
          })
        })
    }
  }

  // ---------------------------------------------------------------------------

  public uploadFile(files: File[]) {
    for (const file of files) {
      const formData = new FormData()

      formData.append('file', file)

      // @ts-ignore
      this.$http.post(`/admins/rest/v1/uploads`,
        formData, {
          // emulateJSON: true,
          // headers: {
          //   'Content-Disposition': `form-data; name="photo"; filename="${file.name}"`
          // }
        },
      ).then((response: any) => {
        this.$store.dispatch(`${STORE_KEY}/addAttachments`, response.data.upload)
      })

      // @ts-ignore
      this.$v.form.icon_upload_id.$touch()
    }
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    // Проверяем, лежит ли в параметрах уже заполненная форма (выбрал поле inn), если у нас создание бренда вызывается из MerchantForm
    if ('inn' in this.$route.params) {
      this.$store.dispatch(`${STORE_KEY}/initState`, {
        id: null,
        predefined: {},
      })
    }
    else {
      this.$store.dispatch(`${STORE_KEY}/initState`, {
        id: this.$route.params.id,
        predefined: this.$route.query,
      })
    }
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
