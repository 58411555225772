

















































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { getFieldError } from '@/core/tools/forms/get_field_error'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
import ComboboxSelectedObserver from '@/components/Controls/ComboboxSelectedObserver.vue'


@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form', 'formDirty',
    ]),
  },
  methods: {
    getFieldError,
  },
  validations() {
    const rules: any = {
      category_id: {},
      name: {},
      brand_owner: {},
      inn: {},
      kpp: {},
      legal_address: {},
      owner_type: {},
      has_patent: {},
      reg_info: {},
      reg_date: {},
      reg_date_to: {},
      website: {},
      phone_hotline: {},
      show_on_mainpage: {},
      owner_id: {},
      icon_upload_id: {},

      outlet_filter_city: {},
    }

    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
    ComboboxSelectedObserver,
  },
})
export default class CreateContractWithCompany extends Vue {
  public organisation_types: any = {
    ip: { name: 'ИП' },
    ooo: { name: 'ООО' },
  }
  public imageError: boolean = false

  get isNew() {
    return !Number.isInteger(Number(this.$route.params.id))
  }

  get titleStack() {
    return [
      'Бренды', (this.isNew ? 'Новый бренд' : 'Просмотр бренда'),
    ]
  }

  public format_date(date: string) {
    const parts = date.split('-')
    return parts[2] + '.' + parts[1] + '.' + parts[0]
  }

  public tableOutletsClick(row: any) {
    this.$router.push({ name: 'outlet-observe', params: { id: row.id } })
  }

  public tableClick(row: any) {
    this.$router.push({ name: 'merchant-observe', params: { id: row.id } })
  }

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public onCitySearch(city: string) {
    this.$store.dispatch(`${STORE_KEY}/cityFilter`, { city_name: city })
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/initState`, { id: this.$route.params.id })
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
