




import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

// import { ServerResult } from '@/core/http/server_result';
// import { QueryBuilder } from '@/core/uql';
import { AxiosResponse } from 'axios'

@Component
export default class ComboboxSelectedObserver extends Vue {
  // $db!: (queries: any[]) => Promise<ServerResult>
  // $q!: (queryName: string) => QueryBuilder;

  @Prop() public value!: any
  @Prop({ type: String }) public schema!: string
  @Prop({ type: Array }) public data!: any[]
  @Prop({ type: String }) public keyField!: string
  @Prop({ type: String }) public labelField!: string
  @Prop({ type: String }) public relField!: string
  @Prop({ type: String }) public listWrapper!: string

  @Prop() public relFilter!: any

  @Prop({ type: String }) public position!: string
  @Prop({ type: Boolean }) public multiple!: boolean
  @Prop({ type: Boolean, default: false }) public disabled!: boolean

  @Prop({ type: String }) public placeholder!: string

  public localValue: any = null
  public selectdItemLabel: any = ''
  public dropdownActive = false

  public items: any[] = []
  public itemsForDisplay: any[] = []

  public searchText: string = ''

  public created() {
    //
  }

  public mounted() {
    this.selectdItemLabel = this.placeholder

    if (this.schema) {
      this.loadData()
    }
    else {
      this.items = this.data
      this.onValueChange(this.value)
      this.updateDisplayList()
    }
  }

  public loadData() {
    // const QNAME = `SELECT_COMBOBOX_LIST_${this.schema.toUpperCase()}`;
    // console.log('QNAME', QNAME);

    // нужно только для селектов у которых данные должны появляться после выбора первого
    // if (this.relField && !this.relFilter) {
    //   return;
    // }

    const params: any = {
      per_page: 1000,
      // order_by: 'id',
      // order_direction: 'asc'
    }

    if (this.relField) {
      params[this.relField] = this.relFilter ? this.relFilter : 0
    }

    (this as any).$http
      .get((`/admins/rest/v1/${this.schema}`), { params })
      .then((response: AxiosResponse) => {
        console.log('response.body', response.data)
        // response.data.importers
        this.items = response.data[this.listWrapper ? this.listWrapper : this.schema].map((item: any) => {
          return {
            id: item[this.keyField],
            label: item[this.labelField],
          }
        })

        if (this.multiple && !Array.isArray(this.value)) {
          this.onValueChange([this.value])
        }
        else {
          this.onValueChange(this.value)
        }
        this.updateDisplayList()
      })
      .catch((error: any) => {
        // eslint-disable-next-line
        console.error('Fatal error', error)

      })

    // this.$db([
    //   this.$q(QNAME)
    //   .select()
    //   .fileds({
    //       id: `${this.schema}.${this.keyField}`,
    //       label: `${this.schema}.${this.labelField}`
    //   })
    //   .order((_: any) => {
    //       return [
    //           _.asc([`${this.schema}.${this.labelField}`]),
    //       ];
    //   })
    //   .from(this.schema)
    //   .where((_: any) => {
    //     if(this.relField) {
    //       return _.eq(`${this.schema}.${this.relField}`, _.const(this.relFilter));
    //     } else {
    //       return undefined;
    //     }
    //   })
    //   .limit(1000),
    // ]).then(((result: ServerResult) => {
    //   this.items = result.getOperation(QNAME).getContent().documents;

    //   if(this.multiple && !Array.isArray(this.value)) {
    //     this.onValueChange([this.value]);
    //   } else {
    //     this.onValueChange(this.value);
    //   }
    //   this.updateDisplayList()
    // }));
  }

  @Watch('value')
  public onValueChange(newVal: any) {
    // console.log(newVal);
    this.localValue = newVal

    if (this.localValue) {
      if (Array.isArray(this.localValue)) {
        const selectdItems = this.items.filter((item) => this.localValue.includes(item.id))

        if (selectdItems.length > 0) {
          this.selectdItemLabel = selectdItems.map((item) => item.label).join(', ')
        }
        else {
          this.selectdItemLabel = ''
        }

      }
      else {
        const selectdItem = this.items.find((item) => item.id === this.localValue)

        if (selectdItem) {
          this.selectdItemLabel = selectdItem.label
        }
        else {
          this.selectdItemLabel = ''
        }
      }
    }
    else {
      this.selectdItemLabel = ''
    }
  }

  @Watch('relFilter')
  public onRelFilterChange(newVal: any) {
    console.log('onRelFilterChange')
    this.updateValue(null)
    this.loadData()
  }

  public updateValue(value: any) {
    this.onValueChange(value)
    this.$emit('input', value)

    if (Array.isArray(this.localValue)) {
      const selectdItems = this.items.filter((item) => this.localValue.includes(item.id))

      this.$emit('input-object', selectdItems)

    }
    else {
      const selectdItem = this.items.find((item) => item.id === this.localValue)

      this.$emit('input-object', selectdItem)
    }
  }

  public activeChange($event: any) {
    this.dropdownActive = $event
    console.log('this.dropdownActive', this.dropdownActive, this.$refs.searchInput)
    if (this.dropdownActive) {
      (this.$refs.searchInput as any).focus()
    }
  }

  @Watch('searchText')
  public updateDisplayList() {
    this.$set(this, 'itemsForDisplay', (this.items || []).filter((item: any) => {
      return item.label.toLowerCase().includes(this.searchText)
    }))
  }

  // search($event) {
  //   throttle
  // }
}
