import Vue from 'vue'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { merge } from 'lodash'

interface BrandFormState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  object: any;
  form: any;

  ooo_list: any[];
}

const $state: BrandFormState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  object: null,
  form: null,

  ooo_list: [],
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  category_id: null,
  brand_owner: null,
  name: null,
  inn: null,
  kpp: null,
  owner_type: null,
  legal_address: null,
  has_patent: false,
  reg_info: null,
  reg_date: null,
  reg_date_to: null,
  website: null,
  phone_hotline: null,
  owner_id: null,
  show_on_mainpage: false,
  logo_upload_id: null,
  logo_document: {},

  outlet_filter_city: null,

  merchants: [],
}

const AVAILABLE_FIELDS = [
  'category_id', 'name', 'brand_owner', 'show_on_mainpage', 'logo_upload_id', 'owner_id',
  'inn', 'kpp', 'owner_type', 'has_patent', 'legal_address', 'reg_info', 'reg_date', 'reg_date_to', 'website', 'phone_hotline',
]

export const $actions: ActionTree<BrandFormState, RootState> = {

  initState({ commit, state }, { id }) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      const params: any = {}
      params.brand_id = id

      vm.$http.all([
        vm.$http.get(`/admins/rest/v1/brands/${id}`),
        vm.$http.get(`/admins/rest/v1/merchants`, { params }),
        vm.$http.get('/admins/rest/v1/receipts', { params }),
      ])
        .then((responses: AxiosResponse[]) => {
          const brand_form = responses[0].data.brand
          brand_form.merchants = responses[1].data.merchants
          brand_form.outlets = []
          const receipts = responses[2].data.receipts

          for (let i = 0; i < brand_form.merchants.length; i++) {
            for (let j = 0; j < brand_form.merchants[i].outlets.length; j++) {
              const outlet = brand_form.merchants[i].outlets[j]

              let receipts_count = 0
              for (let k = 0; k < receipts.length; k++) {
                if (receipts[k].outlet_id === outlet.id) {
                  receipts_count++
                }
              }

              outlet.receipts_count = '' + receipts_count

              if (outlet.address === null) {
                outlet.address = '(Не указан)'
              }
              else if (outlet.address.length === 0) {
                outlet.address = '(Не указан)'
              }

              brand_form.outlets.push(outlet)
            }
          }

          commit('SET_FORM_DATA', {
            object: brand_form,
          })
          commit('LOADED_SUCCESS')

          console.log(brand_form)

          resolve(state.object)
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })

    })
  },

  cityFilter({ commit, state }, { city_name }) {
    commit('CHANGE_VALUE', { field: 'outlet_filter_city', newValue: city_name })

    const vm = (this as any)._vm

    const params: any = {}
    params.brand_id = state.form.id

    vm.$http.all([
      vm.$http.get(`/admins/rest/v1/brands/${state.form.id}`),
      vm.$http.get(`/admins/rest/v1/merchants`, { params }),
      vm.$http.get('/admins/rest/v1/receipts', { params }),
    ])
      .then((responses: AxiosResponse[]) => {
        const brand_form = responses[0].data.brand
        brand_form.merchants = responses[1].data.merchants
        brand_form.outlets = []
        const receipts = responses[2].data.receipts

        for (let i = 0; i < brand_form.merchants.length; i++) {
          for (let j = 0; j < brand_form.merchants[i].outlets.length; j++) {
            const outlet = brand_form.merchants[i].outlets[j]

            if (city_name.length > 0) {
              if (outlet.address_city === null) {
                continue
              }

              if (!outlet.address_city.toLowerCase().includes(city_name.toLowerCase())) {
                continue
              }
            }

            let receipts_count = 0
            for (let k = 0; k < receipts.length; k++) {
              if (receipts[k].outlet_id === outlet.id) {
                receipts_count++
              }
            }

            outlet.receipts_count = '' + receipts_count

            if (outlet.address === null) {
              outlet.address = '(Не указан)'
            }
            else if (outlet.address.length === 0) {
              outlet.address = '(Не указан)'
            }

            brand_form.outlets.push(outlet)
          }
        }

        commit('SET_FORM_DATA', {
          object: brand_form,
        })
      })
  },
}

export const $mutations: MutationTree<BrandFormState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },
  CHANGE_VALUE(state: BrandFormState, { field, newValue }) {
    state.form[field] = newValue
  },
  SET_FORM_DATA(state, { object }) {
    state.object = object

    const formData = cloneDeep(state.object)

    state.form = {
      ...formData,
    }
  },
}

const $getters = {}

export const STORE_KEY = '$_brand_observe'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
};
